export const CACHE_SEVEN_DAYS = 7 * 1000 * 60 * 60 * 24;

export const identifierList = [
  'identical',
  'phonetic',
  'orthographic',
  'foreign_equivalent',
  'semantic',
  'reversed',
  'truncated',
  'expanded',
];
